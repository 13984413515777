import { API_URL } from '../../../app-config/constants';
import { get, remove } from '../request';
const BASE_URL = `${API_URL}/copilot/history`;
getHistory.operation = 'READ';
export function getHistory() {
    return get(BASE_URL);
}
getChat.operation = 'READ';
export function getChat(id) {
    return get(`${BASE_URL}/${id}`);
}
deleteChat.operation = 'DELETE';
export function deleteChat(id) {
    return remove(`${BASE_URL}/${id}`);
}
