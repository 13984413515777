import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../../../../api';
import { sendDemoRequest } from '../../../../../../api/custom/GetADemo';
import BookConsultation from '../../../../../components/BookConsultation';
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import { useAppContext } from '../../../../../hooks/useAppContext';
import { initHubspot } from '../../../../../services/Hubspot/hubspot';
import { initNavattic, navatticOnEvent } from '../../../../../services/Navattic/navattic';
import loadScript from '../../../../../services/loadScript';
import styles from '../styles.m.less';
const GetADemoModalTabs = ({ tab, close }) => {
    const { user } = useAppContext();
    const [showHubspotForm, setShowHubspotForm] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [navatticLoaded, setNavatticLoaded] = useState(false);
    const [hideSpinner, setHideSpinner] = useState(false);
    const loadHubspotForm = useCallback(async () => {
        await loadScript('https://js.hsforms.net/forms/v2.js', 'hubspot_demo');
        initHubspot(user, setShowConfirmation);
    }, [user]);
    const loadScripts = useCallback(async () => {
        // This onboardingSettings check is here to handle old trial companies from before May 2024,
        // when the OnboardingSettings form was introduced.
        const onboardingSettings = await api.onboardingSettings.getOnboardingSettings();
        if (!onboardingSettings) {
            await loadHubspotForm();
        }
        else if (onboardingSettings.requestedDemo) {
            setShowConfirmation(true);
        }
        else {
            setShowHubspotForm(false);
        }
        await loadScript('https://js.navattic.com/embed-events.js', 'navattic_form');
        initNavattic(user);
        setNavatticLoaded(true);
        navatticOnEvent(close);
    }, [user, close, loadHubspotForm]);
    const sendDemoRequestHandler = async () => {
        const result = await sendDemoRequest();
        if (!result) {
            await loadHubspotForm();
            setShowHubspotForm(true);
        }
        setShowConfirmation(!!result);
    };
    useEffect(() => {
        loadScripts();
    }, [loadScripts]);
    const renderDemoTabContent = () => {
        if (showConfirmation) {
            return (_jsxs("div", { children: [_jsx("h2", { children: "Demo requested" }), _jsx("p", { children: "Thank you for requesting a Small Improvements demo. Our customer team will contact you shortly." })] }));
        }
        return showHubspotForm ? (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Request a Demo" }), _jsx("div", { id: "hubspotDemoForm" })] })) : (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Request a Demo" }), _jsx("p", { children: "In your customized demonstration, we'll:" }), _jsxs("ul", { children: [_jsx("li", { children: "Discuss your performance management needs" }), _jsx("li", { children: "Provide a personalized walkthrough with tailored recommendations" }), _jsx("li", { children: "Follow up with specific content and additional walkthroughs" })] }), _jsx("p", { children: "Click the button below to confirm the request:" }), _jsx(Button, { onClick: sendDemoRequestHandler, kind: "PRIMARY", children: "Request a demo" })] }));
    };
    return (_jsx("div", { className: styles.tabsContainer, children: _jsxs("div", { className: styles.hubspotContainer, children: [_jsx("div", { className: classNames(styles.hide, { [styles.show]: tab === 'demo' }), children: renderDemoTabContent() }), _jsx("div", { className: classNames(styles.hide, { [styles.show]: tab === 'consultation' }), children: user.company.server !== 'prod-eu' && _jsx(BookConsultation, {}) }), _jsx("div", { className: classNames(styles.hide, { [styles.show]: tab === 'tutorial' }), children: tab === 'tutorial' && navatticLoaded && (_jsxs(_Fragment, { children: [!hideSpinner && (_jsx("div", { className: styles.overlaySpinner, children: _jsx(Loader, {}) })), _jsx("iframe", { src: `https://capture.navattic.com/clt67lmk0000p0fjtga120nvi?user.fullName=${user.firstName}`, onLoad: () => setTimeout(() => setHideSpinner(true), 2000), style: {
                                    position: 'fixed',
                                    border: 'none',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    margin: 0,
                                    padding: 0,
                                    zIndex: 999999,
                                }, allow: "fullscreen" })] })) })] }) }));
};
export default GetADemoModalTabs;
