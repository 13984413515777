// Even tho we don't need reusability, the values are hold in this file to avoid repetition as we needunique names for each component
'data-intercom-target';
export const GENERAL = {
    SHARE_BUTTON_CONFIRM_MODAL: 'share-button-confirm-modal',
};
export const NAVIGATION = {
    EMPLOYEE_TAB: 'employee-tab',
    ADMIN_TAB: 'admin-tab',
    EMPLOYEE_MEETINGS: 'employee-meetings',
    EMPLOYEE_PRAISE: 'employee-praise',
    EMPLOYEE_SURVEYS: 'employee-surveys',
    EMPLOYEE_OBJECTIVES: 'employee-objectives',
    EMPLOYEE_FEEDBACK: 'employee-feedback',
    EMPLOYEE_REVIEWS: 'employee-reviews',
    ADMIN_MEETINGS: 'admin-meetings',
    ADMIN_PRAISE: 'admin-praise',
    ADMIN_SURVEYS: 'admin-surveys',
    ADMIN_OBJECTIVES: 'admin-objectives',
    ADMIN_360_FEEDBACK: 'admin-360-feedback',
    ADMIN_ANYTIME_FEEDBACK: 'admin-anytime-feedback',
    ADMIN_REVIEWS: 'admin-reviews',
    ADMIN_MESSAGES: 'admin-messages',
};
export const TOUR_EMPLOYEE_MEETINGS = {
    BUTTON_CREATE_MEETING: 'button-employee-create-meeting',
    INPUT_SELECT_COLLEAGUE: 'input-meeting-select-colleague',
    INPUT_DATE: 'input-meeting-date',
    INPUT_TIME: 'input-meeting-time',
    INPUT_TEMPLATE: 'input-meeting-template',
    BUTTON_CREATE_MODAL: 'button-create-meeting-modal',
};
export const TOUR_EMPLOYEE_PRAISE = {
    BUTTON_CREATE: 'button-employee-create-praise',
    INPUT_BADGE: 'input-praise-select-badge',
    INPUT_SELECT_COLLEAGUE: 'input-praise-select-colleague',
    INPUT_TITLE: 'input-praise-title',
    INPUT_DESCRIPTION: 'input-praise-description',
    BUTTON_CREATE_MODAL: 'button-create-praise-modal',
};
// added as direct strings in angular
export const TOUR_EMPLOYEE_OBJECTIVES = {
    BUTTON_CREATE: 'button-employee-create-objective',
    CYCLE_SUMMARY: 'objective-cycle-summary',
    INPUT_TITLE: 'input-objective-title',
    INPUT_DESCRIPTION: 'input-objective-description',
    INPUT_KEY_RESULTS: 'input-objective-key-results',
    INPUT_DATE: 'input-objective-date',
    INPUT_CATEGORY: 'input-objective-category',
    INPUT_VISIBILITY: 'input-objective-visibility',
    BUTTON_CREATE_MODAL: 'button-create-objective-modal',
};
export const TOUR_EMPLOYEE_ANYTIME_FEEDBACK = {
    BUTTON_CREATE: 'button-employee-create-anytime-feedback',
    BUTTON_GIVE_FEEDBACK: 'button-give-feedback',
    INPUT_REVIEWEE: 'input-anytime-feedback-reviewee',
    INPUT_TITLE: 'input-anytime-feedback-title',
    INPUT_DESCRIPTION: 'input-anytime-feedback-description',
    BUTTON_WRITE_1: 'button-anytime-feedback-write-1',
    BUTTON_WRITE_2: 'button-anytime-feedback-write-2',
    BUTTON_SHARE: 'button-anytime-feedback-share',
    BUTTON_SHARE_CONFIRM: 'button-anytime-feedback-share-confirm',
    BUTTON_HELPFUL_RESOURCES: 'button-anytime-feedback-helpful-resources',
};
export const TOUR_ADMIN_PULSE_SURVEYS = {
    BUTTON_CREATE: 'button-create-survey',
    BUTTON_PARTICIPANTS_ENTIRE_COMPANY: 'button-participants-entire-company',
    BUTTON_NOTIFICATIONS_DONE: 'button-survey-notifications-done',
    BUTTON_CREATE_FORM: 'button-survey-create',
    BUTTON_CREATE_CONFIRM: 'button-survey-create-confirm',
};
